import { Title } from '@solidjs/meta';
import { Button, Errors, Heading, Input, Label, TextField, Container, Page, Section } from '@troon/ui';
import { Formspree, formspreeAction, useFormspree } from '@troon/forms';
import { Match, Switch } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { getConfigValue } from '../../modules/config';
import { useUser } from '../../providers/user';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';

export default function ContactUsPage() {
	const formAction = useFormspree(action);
	const submission = useSubmission(formAction);
	const user = useUser();

	return (
		<>
			<Title>Troon Cares | Troon</Title>

			<Container>
				<Page>
					<Grid>
						<GridFive>
							<Section>
								<Heading as="h1">Troon Cares</Heading>
								<p>
									Kindness and caring are key foundational tenets of Troon and the Troon family of brands. To that end,
									and through the support of Troon and generous gifts from our associates, we have established the Troon
									Cares Associate Relief Fund to provide short-term assistance to help bridge the financial gap. The
									goal of this fund is to assist full -time associates experiencing significant financial hardship
									during challenging times caused by the on-going pandemic and/or other catastrophic events or
									hardships.
								</p>

								<p>
									Our Troon Cares Associate Relief portal is designed to offer information about our associate relief
									program. If you are a full -time associate of Troon or a Troon company/brand and are facing a
									difficult situation, you can easily determine if you’re eligible for program assistance and apply
									online. And if you’re looking to support colleagues in need, there are many ways to make a
									contribution to the fund, including online.
								</p>
							</Section>
						</GridFive>

						<GridSeven class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
							<Switch>
								<Match when={submission.result}>
									<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
										<IconCircleCheckCustom class="text-8xl" />
										<Heading as="h1" size="h2">
											Message received!
										</Heading>
										<p class="text-lg">
											Thanks for contacting us! Our team is reviewing your request and we will get in touch with you
											shortly.
										</p>
									</div>
								</Match>
								<Match when>
									<Formspree action={formAction}>
										<div class="flex gap-4">
											<TextField name="First name" required class="grow">
												<Label>First name</Label>
												<Input autocomplete="given-name" value={user()?.me.firstName} />
											</TextField>

											<TextField name="Last name" required class="grow">
												<Label>Last name</Label>
												<Input autocomplete="family-name" value={user()?.me.lastName} />
											</TextField>
										</div>

										<TextField name="email" required>
											<Label>Email address</Label>
											<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
										</TextField>

										<TextField name="Question" required>
											<Label>Question</Label>
											<Input multiline />
										</TextField>

										<Errors />
										<Button type="submit" class="w-fit">
											Submit
										</Button>
									</Formspree>
								</Match>
							</Switch>
						</GridSeven>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'troon-cares');
